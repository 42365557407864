import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as Widget from '../Components/Widget';
import ParseQuery from '../Utils/ParseQuery';
import * as L from '../Utils/Lang';

class SocialLoginResultPage extends React.Component {
  state = {
    data: null,
    errorText: '',
  };
  componentDidMount() {
    let { location, navActions, appActions } = this.props;
    let queryParams = ParseQuery(location.search);
    let data = JSON.parse(decodeURIComponent(queryParams.data));
    this.setState({ data });
    // clear temp storage for lang
    let lang = localStorage.getItem('lang-before-redirect');
    localStorage.removeItem('lang-before-redirect');
    appActions.setLang(lang);
    if (data.token) {
      window.localStorage.setItem('token', data.token);
      navActions.push('/me');
    }
  }

  render() {
    let { data } = this.state;
    if (data && data.error) {
      return (
        <Widget.Col
          justify="center"
          align="center"
          extraCss="padding-top: 40px;"
        >
          <div style={{ color: 'tomato', fontSize: 18, padding: 10 }}>
            {L.s('error-message')}
          </div>
          <div style={{ color: 'tomato', fontSize: 18, padding: 10 }}>
            {data.error}
          </div>
        </Widget.Col>
      );
    }
    return (
      <Widget.Row justify="center" align="center" extraCss="height: 100%;">
        <Widget.Spinner />
      </Widget.Row>
    );
  }
}

export default withPage(
  connect(
    null,
    ActionCreator
  )(SocialLoginResultPage)
);
